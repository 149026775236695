<template>
  <div class="index-container">
    <div class="main">
      <div class="banner-container">
        <div class="banner-box">
          <div class="banner-box-left">
            <div class="banner-text">{{ $t("message.index.bannerText1") }}</div>
            <div class="banner-text">{{ $t("message.index.bannerText2") }}</div>
            <div class="get-now-btn" @click="handleGoStartPage(3)">{{ $t("message.index.getNow") }}</div>
          </div>
          <img src="../../../assets/images/banner.png" class="banner-img" alt="" />
        </div>
      </div>
      <!-- select -->
      <div class="content" id="start">
        <div class="title">{{ $t("message.index.contentTitle") }}</div>
        <div class="select-container">
          <div class="select-box">
            <select class="select_list" @change="handleCountryChange" v-model="curCountryName">
              <option v-for="(item, index) in countryList" :key="'country' + index" :value="item">
                {{ item }}
              </option>
            </select>
            <div class="down_arrow"></div>
          </div>
          <div class="select-box">
            <select class="select_list" @change="handleDocumentChange" v-model="curDocumentInfo">
              <option v-for="(item, index) in allSpecs[curCountryName]" :key="'document' + index" :value="item">
                {{ item.document_type }}
              </option>
            </select>
            <div class="down_arrow"></div>
          </div>
        </div>
        <div class="info-box">
          <div class="info-box-title">{{ $t("message.index.contentTitle2") }}</div>
          <div class="price">
            {{ $t("message.index.downloadText") }} <span class="old-price">$3.99</span>
            <span class="real-price">$1.99</span>
            <br />
            <span class="off-price"> ({{ $t("message.index.priceOff") }})</span>
          </div>
          <!-- get now btn -->
          <div class="get-btn" @click="handleGoStartPage(1)">
            {{ $t("message.index.getNow") }}
          </div>
          <!-- desc -->
          <div class="desc-box">
            <div class="desc-list" v-for="(item, index) in descList" :key="index">
              <div class="desc-check"></div>
              <div>{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- product -->
      <div class="product-box">
        <div class="product-box-title">{{ $t("message.index.productTitle") }}</div>
        <div class="product-container">
          <div class="product-list">
            <img src="../../../assets/images/card1.svg" class="card-list" alt="" />
            <div class="product-desc">{{ $t("message.index.productText1") }}</div>
          </div>
          <div class="product-list">
            <img src="../../../assets/images/card2.svg" class="card-list" alt="" />
            <div class="product-desc">{{ $t("message.index.productText2") }}</div>
          </div>
          <div class="product-list">
            <img src="../../../assets/images/card3.svg" class="card-list" alt="" />
            <div class="product-desc">{{ $t("message.index.productText3") }}</div>
          </div>
        </div>
      </div>
      <!-- download -->
      <div class="download-box" id="Help">
        <div class="download-container">
          <div class="download-box-title">{{ $t("message.index.downloadText2") }}</div>
          <img src="../../../assets/images/download.svg" class="download-svg" alt="" />
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { getCountries } from "@/api/common.js";
import { reportWebVitals } from "@/utils/ga.js";
import { mapState, mapMutations } from "vuex";
import Footer from "@/components/Footer.vue";

export default {
  name: "index",

  data() {
    return {
      countryList: [],
      curCountryName: "",
      documentList: {},
      curDocumentInfo: {},
      descList: [],
      allSpecs: {},

      search: "",
      ifExp: false,
      variant: 0,

      id: null, //国家id
    };
  },
  components: {
    Footer,
  },
  computed: mapState([
    // 映射 this.count 为 store.state.count
    "documentInfo",
  ]),
  mounted() {
    const locale = this.$i18n.locale;
    const messages = this.$i18n.messages;
    const desc = messages[locale].message.index.desc;
    this.descList = desc.split("/");
    this.id = this.$route.query.id;
    this.getCountries();
  },
  methods: {
    ...mapMutations([
      "setDocumentInfo", // 将 `this.setDocumentInfo()` 映射为 `this.$store.commit('setDocumentInfo')`
    ]),
    handleCountryChange() {
      if (!this.allSpecs[this.curCountryName]) {
        return;
      }
      this.curDocumentInfo = this.allSpecs[this.curCountryName][0];
      // 切换国家打点
      reportWebVitals("country_select_index", 1, "spec", "", {
        countryName: this.curCountryName,
      });
    },
    handleDocumentChange() {
      // 切换类型打点
      reportWebVitals("photo_type_select_index", 1, "spec", "", {
        photoId: this.curDocumentInfo.id,
      });
    },
    handleGoStartPage(type) {
      if (type == 1) {
        reportWebVitals("middle_getnow_click_index", "", "getnow", "core");
      } else if (type == 2) {
        reportWebVitals("bottom_getnow_click_index", "", "getnow", "core");
      } else {
        reportWebVitals("first_getnow_click_index", "", "getnow", "core");
      }
      this.setDocumentInfo(this.curDocumentInfo);
       this.$router.push({ name: "start",query:{id: this.$route.query.id} });
    },
    getCountries() {
      getCountries()
        .then((res) => {
          if (res?.body?.code == 0) {
            const data = res.body.data;
            this.allSpecs = data?.specs?.specs || {};
            this.countryList = data?.specs?.countries || [];
            // url 中自带国家id
            if (this.id) {
              // 根据id 获取国家和类型信息
              for (let k in this.allSpecs) {
                const item = this.allSpecs[k];
                for (let i = 0; i < item.length; i++) {
                  if (item[i].id == this.id) {
                    this.curCountryName = k;
                    this.curDocumentInfo = this.allSpecs[this.curCountryName][i];
                    break;
                  }
                }
              }
            } else if (this.documentInfo) {
              this.curCountryName = this.documentInfo.country;
              this.curDocumentInfo = this.documentInfo;
            } else {
              this.curCountryName = (this.countryList && this.countryList[0]) || "";
              this.documentList = this.allSpecs[this.curCountryName];
              this.curDocumentInfo = (this.documentList && this.documentList[0]) || "";
            }
            reportWebVitals("spec_success", 1, "spec", "", {
              code: res.body.code,
            });
          } else {
            reportWebVitals("spec_fail", 1, "spec", "", {
              code: res.body.code,
            });
          }
        })
        .catch((err) => {
          reportWebVitals("spec_fail", 1, "spec", "", { code: "-1" });
          console.error(err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index-container {
  .main {
    .banner-container {
      background: #ffbf00;
      .banner-box {
        width: 1200px;
        margin: 0 auto;
        padding-top: 50px;
        padding-bottom: 40px;
        padding-left: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .banner-box-left {
          .banner-text {
            font-size: 30px;
            font-weight: 700;
            color: #242a33;
            // text-align: center;
            // margin-bottom: 14px;
          }
          .get-now-btn {
            width: 204px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: #242a33;
            border-radius: 6px;
            font-size: 20px;
            background: #fff;
            margin-top: 42px;
            cursor: pointer;
          }
        }

        .banner-img {
          width: 450px;
          height: auto;
          padding-left: 90px;
        }
      }
    }
    .select-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .select-box {
        width: 300px;
        margin: 0 10px;

        .select_list {
          width: 100%;
          height: 36px;
          background: #fff;
          font-size: 14px;
          padding-left: 15px;
          margin: 0;
        }
        .down_arrow {
          right: 10%;
          width: 12px;
          height: 12px;
          right: 2%;
        }

        .down_arrow::after {
          height: 8px;
          width: 8px;
        }
      }
    }
    .info-box {
      width: 1018px;
      background: rgba(255, 214, 0, 0.06);
      // background: #FFD600;
      margin: 23px auto 0;
      padding-bottom: 20px;
      padding-top: 42px;
      .info-box-title {
        font-size: 20px;
        text-align: center;
        color: #242a33;
        font-weight: 500;
      }
    }
    .content {
      padding: 50px 0;
      .title {
        font-size: 30px;
        color: #242a33;
        text-align: center;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .price {
        font-size: 16px;
        color: #242a33;
        text-align: center;
        font-weight: 400;
        margin-top: 20px;
        .old-price {
          color: #cc210f;
          text-decoration: line-through;
        }
        .real-price {
          font-size: 30px;
          font-weight: 700;
        }
        .off-price {
          color: #15993d;
        }
      }
      .get-btn {
        width: 150px;
        height: 36px;
        line-height: 36px;
        background: #ffbf00;
        border-radius: 6px;
        margin: 20px auto 0;
        text-align: center;
        font-size: 14px;
        color: #242a33;
        font-weight: 600;
        cursor: pointer;
      }
      .desc-box {
        margin-top: 18px;
        padding-left: 50px;
        width: 90%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .desc-list {
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #242a33;
          // line-height: 16px;
          font-weight: 400;
          margin-bottom: 4px;
          // line-height: 32px;
          margin-left: 10px;
          .desc-check {
            width: 8px;
            height: 12px;
            border-color: #44c562;
            border-style: solid;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            margin-right: 14px;
            margin-top: -2px;
          }
        }
      }
    }
    .product-box {
      background: #ffbf00;
      padding: 50px 0;
      .product-box-title {
        font-size: 30px;
        color: #242a33;
        text-align: center;
        font-weight: 700;
        margin-bottom: 60px;
      }
      .product-container {
        display: flex;
        justify-content: center;
        .product-list {
          margin-bottom: 60px;
          .card-list {
            width: 180px;
            height: auto;
            margin: 0 auto;
          }
          .product-desc {
            width: 300px;
            margin: 30px auto 0;
            font-size: 20px;
            color: #242a33;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
    .download-box {
      padding: 99px 0;

      .download-container {
        width: 1200px;
        margin: 0 auto;
        padding-left: 130px;
        display: flex;
        align-items: center;
      }
      .download-box-title {
        width: 600px;
        font-size: 30px;
        color: #242a33;
        // text-align: center;
        font-weight: 700;
      }
      .download-svg {
        width: 135px;
        height: auto;
        margin: 0 auto;
      }
    }
  }
  .fix-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    color: #242a33;
    .btn-box {
      width: 85%;
      height: 90px;
      margin: 0 auto;
      background: #ffbf00;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .get-btn {
        font-size: 30px;
        color: #242a33;
        font-weight: 600;
      }
      .btn-desc {
        .real-price {
          font-weight: bolder;
        }
      }
      .off-price {
        width: 120px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        background: #15993d;
        border-radius: 0px 8px 0px 8px;
        font-size: 24px;
        color: #ffffff;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
</style>
